<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.veiculo.titulos.listagem')"
      :total-registros="tabela.dados.length"
      :titulo-botao-novo="$t('modulos.veiculo.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Veiculo', 'Inserir')"
      :sem-filtro="!buscarPermissao('Veiculo', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    />
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Veiculo', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :mostrar-seletor="buscarPermissao('Veiculo', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @filtrarGlobal="listarRegistros"
      @nova-ordenacao="ordenacaoColuna"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('Veiculo', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('Veiculo', 'Visualizar')"
              @click="abrirVisualizar(slotProps.data)"
            >
              {{ $t('geral.botoes.visualizar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import VeiculoService from '@common/services/cadastros/VeiculoService';
import helpers from '@/common/utils/helpers';

export default {
  components: {
    CabecalhoPagina,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'placa',
            text: this.$t('modulos.veiculo.tabela.placa'),
            sortable: true,
          },
          {
            value: 'modelo',
            text: this.$t('modulos.veiculo.tabela.modelo'),
            sortable: true,
          },
          {
            value: 'fabricante',
            text: this.$t('modulos.veiculo.tabela.fabricante'),
            sortable: true,
          },
          {
            value: 'principalCondutorColaborador.nome',
            text: this.$t(
              'modulos.veiculo.tabela.principalCondutorColaborador'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.veiculo.titulos.listagem')
    );
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Veiculo', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
      this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.filtroGlobal = this.filtroAplicado.filter;
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
    this.paginaAtual = (filtros?.paginaAtual - 1) * this.tabela.porPagina || 0;
    this.itensPorpagina = filtros?.porPagina ?? 10;
    this.colunasSelecionadas = filtros?.colunasSelecionadas ?? [];
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.paginaAtual = paginaAtual;
      this.tabela.porPagina = porPagina;
      helpers.salvarStateFiltros(this, {
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
        colunasSelecionadas: this.colunasSelecionadas,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      VeiculoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.tabela.selecionados.forEach((item) => {
          VeiculoService.excluir(item.id)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(this.$t(`modulos.veiculo.exclusao_sucesso`));
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.tabela.selecionados = [];
            });
        });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'veiculo-novo' });
    },
    abrirEditar: function (item, visualizar = false) {
      const query = visualizar ? { visualizar } : null;
      this.$router.push({
        name: 'veiculo-editar',
        params: { id: item.id },
        query,
      });
    },
    abrirVisualizar: function (item) {
      this.$router.push({
        name: 'veiculo-visualizar',
        params: { id: item.id },
      });
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros();
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
  },
};
</script>
